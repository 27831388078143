import React from "react";
import { useState } from "react";
function Home() {
  const [showSecretMessage, setShowSecretMessage] = useState(false);

  return (
    <div>
      <h2 className="glitch-text">Hello</h2>
      <p className="rainbow-text">Welcome to my website! I'm Davis!</p>

      {/* Secret Message */}
      <div
        onMouseEnter={() => setShowSecretMessage(true)}
        onMouseLeave={() => setShowSecretMessage(false)}
        style={{ margin: "20px 0", cursor: "pointer" }}
      >
        <p style={{ fontSize: "14px" }}>Hover here for a secret message...</p>
        {showSecretMessage && (
          <p className="rainbow-text">
            The real secret is the bugs we made along the way 🐛
          </p>
        )}
      </div>
    </div>
  );
}

export default Home;
