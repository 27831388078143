import React, { useEffect } from "react";
import { Link as ScrollLink, Element, Events, scrollSpy } from "react-scroll";
import "./App.css";
import Home from "./Home";
import Projects from "./Projects";
import About from "./Aboutme";
function App() {
  useEffect(() => {
    Events.scrollEvent.register("begin", function () {
      console.log("begin", arguments);
    });

    Events.scrollEvent.register("end", function () {
      console.log("end", arguments);
    });

    scrollSpy.update();

    // Cleanup
    return () => {
      Events.scrollEvent.remove("begin");
      Events.scrollEvent.remove("end");
    };
  }, []);

  return (
    <div className="App">
      {/* Top Navigation Bar */}
      <nav className="top-nav">
        <ul className="nav-links">
          <li>
            <ScrollLink
              to="home-section"
              spy={true}
              smooth={true}
              duration={500}
              className="nav-link"
              activeClass="active"
            >
              Home
            </ScrollLink>
          </li>
          <li>
            <ScrollLink
              to="projects-section"
              spy={true}
              smooth={true}
              duration={500}
              className="nav-link"
              activeClass="active"
            >
              Projects
            </ScrollLink>
          </li>
          <li>
            <ScrollLink
              to="about-section"
              spy={true}
              smooth={true}
              duration={500}
              className="nav-link"
              activeClass="active"
            >
              About
            </ScrollLink>
          </li>
        </ul>
      </nav>
      <div className="sections-container">
        <Element name="home-section" className="section">
          <Home />
        </Element>

        <Element name="projects-section" className="section">
          <Projects />
        </Element>
        <Element name="about-section" className="section">
          <About />
        </Element>
        {/* Footer */}
        <footer className="footer">
          <p className="footer-text">
            © {new Date().getFullYear()} Designed and built with ❤️ by Davis
            Chun Zhi Heng. All rights reserved.
          </p>
          <div className="footer-subtext">or by claude in 10 seconds.</div>
        </footer>
      </div>
    </div>
  );
}

export default App;
