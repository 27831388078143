import React, { useState, useEffect } from "react";
import { FolderPlus, File, Lock, ChevronRight, X, Trash2 } from "lucide-react";
import "./Sidebar.css";
import { getApiEndpoint } from "./apiConfig.js";

const Sidebar = ({ activeWorkspace, setActiveWorkspace }) => {
  const [workspaces, setWorkspaces] = useState([
    { id: 1, name: "Davis Workspace", locked: true },
  ]);
  const [showModal, setShowModal] = useState(false);
  const [workspaceName, setWorkspaceName] = useState("");
  useEffect(() => {
    const initialWorkspace = workspaces[0];
    if (!activeWorkspace && initialWorkspace) {
      setActiveWorkspace(initialWorkspace);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCreateWorkspace = (e) => {
    e.preventDefault();
    const newWorkspace = {
      id: workspaces.length + 1,
      name: workspaceName,
      locked: false,
    };
    setWorkspaces([...workspaces, newWorkspace]);
    setWorkspaceName("");
    setShowModal(false);
  };

  const handleDeleteWorkspace = async (workspaceId) => {
    try {
      await fetch(`${getApiEndpoint("xx")}workspace_id=${workspaceId}`, {
        method: "DELETE",
      });
      setWorkspaces(
        workspaces.filter((workspace) => workspace.id !== workspaceId)
      );
    } catch (error) {
      console.error("Delete workspace error:", error);
    }
  };

  return (
    <>
      <div className="workspace-sidebar">
        <div className="workspace-header">
          <File size={20} />
          <h1 className="workspace-title">Namespace Notes</h1>
        </div>
        <div className="workspace-list">
          {workspaces.map((workspace) => (
            <div key={workspace.id}>
              <div
                className={`workspace-item ${
                  activeWorkspace?.id === workspace.id ? "active" : ""
                }`}
                onClick={() => setActiveWorkspace(workspace)}
              >
                <ChevronRight
                  size={16}
                  className={
                    activeWorkspace?.id === workspace.id ? "rotated" : ""
                  }
                />
                <span className="workspace-name">{workspace.name}</span>
                {workspace.locked ? (
                  <Lock size={16} className="workspace-lock" />
                ) : (
                  <button
                    className="delete-button"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteWorkspace(workspace.id);
                    }}
                    aria-label="Delete workspace"
                  >
                    <Trash2 size={16} />
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>
        <button
          className="create-workspace-btn"
          onClick={() => setShowModal(true)}
        >
          <FolderPlus size={16} />
          <span>Create Workspace</span>
        </button>
      </div>
      {showModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <div className="modal-header">
              <h2>Create New Workspace</h2>
              <button
                className="close-button"
                onClick={() => setShowModal(false)}
              >
                <X size={20} />
              </button>
            </div>

            <form onSubmit={handleCreateWorkspace} className="modal-form">
              <div className="form-group">
                <label htmlFor="workspace-name">Workspace Name</label>
                <input
                  id="workspace-name"
                  type="text"
                  value={workspaceName}
                  onChange={(e) => setWorkspaceName(e.target.value)}
                  placeholder="Enter workspace name"
                  required
                  className="modal-input"
                />
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="cancel-button"
                  onClick={() => setShowModal(false)}
                >
                  Cancel
                </button>
                <button type="submit" className="submit-button">
                  Create
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default Sidebar;
