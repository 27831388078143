import React from "react";
import "./Aboutme.css";
import myPhoto from "./images/photo_2025-01-04_18-07-00.jpg";
function About() {
  return (
    <div>
      <div className="about-header">
        <h2 className="glitch-text">About me</h2>
      </div>
      <div className="content-container">
        <div className="profile-container">
          <img src={myPhoto} alt="Me" className="photo" />
          <div className="profile-text">
            <p>
              Hi, I'm Davis Chun, a final-year Computer Science student at SUTD
              specializing in Machine Learning and AI. Through my internships
              and various projects, I've developed end-to-end ML systems ranging
              from industrial IoT to computer vision, driven by my passion for
              solving complex problems through innovative applications of
              machine learning.
            </p>
          </div>
        </div>
        <div className="about-container">
          {/* Left Column */}

          <div className="about-left">
            <h3 className="section-title">Technical Skills</h3>
            <div className="skills-grid">
              <div className="skill-group">
                <h4 className="skill-title">Languages</h4>
                <p className="skill-items">Python • Java • JavaScript</p>
              </div>
              <div className="skill-group">
                <h4 className="skill-title">ML/AI</h4>
                <p className="skill-items">PyTorch • Scikit-learn • OpenCV</p>
              </div>
              <div className="skill-group">
                <h4 className="skill-title">Tools</h4>
                <p className="skill-items">
                  Docker • Git • Linux • Google Cloud Products
                </p>
              </div>
              <div className="skill-group">
                <h4 className="skill-title">Data</h4>
                <p className="skill-items">SQL • PySpark</p>
              </div>
            </div>
          </div>

          {/* Right Column */}
          <div className="about-right">
            <h3 className="section-title">When I'm Not Coding</h3>
            <div className="interests-grid">
              <div className="interest-group">
                <h4 className="interest-title">Sports & Gaming</h4>
                <p className="interest-item">🏸 Badminton</p>
                <p className="interest-item">🎮 League of Legends</p>
                <p className="interest-item">🦸 Marvel Rivals</p>
              </div>
              <div className="interest-group">
                <h4 className="interest-title">Tech Hobbies</h4>
                <p className="interest-item">🛠️ Building & flying drones</p>
                <p className="interest-item">🖨️ 3D printing</p>
                <p className="interest-item">☕ Coffee-powered coding</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
