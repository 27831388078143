import React, { useState, useEffect, useRef } from "react";
import {
  Send as SendIcon,
  File as FileIcon,
  X as XIcon,
  RefreshCcw as RefreshIcon,
} from "lucide-react";
import "./Chatbot.css";
import Sidebar from "./Sidebar";
import { getApiEndpoint } from "./apiConfig.js";

const Chatbot = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState({}); // Changed to object with workspace IDs as keys
  const [processingFiles, setProcessingFiles] = useState({}); // Changed to object with workspace IDs as keys
  const [activeWorkspace, setActiveWorkspace] = useState(null);
  const [deletingFiles, setDeletingFiles] = useState({}); // Changed to object with workspace IDs as keys
  const containerRef = useRef(null);
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [messages]);
  useEffect(() => {
    let statusCheckInterval;

    if (activeWorkspace) {
      const checkStatus = async () => {
        try {
          const statusResponse = await fetch(
            `${getApiEndpoint("checkStatus")}?workspace_id=${
              activeWorkspace.id
            }`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          if (!statusResponse.ok) {
            throw new Error(`HTTP error! status: ${statusResponse.status}`);
          }

          const statusData = await statusResponse.json();
          const processedFiles = [];
          const stillProcessingFiles = [];

          statusData.files.forEach((file) => {
            if (file.status === "processed") {
              processedFiles.push({
                name: file.filename,
                createdAt: file.created_at,
                processedAt: file.processed_at,
              });
            } else if (file.status === "pending") {
              stillProcessingFiles.push({
                name: file.filename,
                status: "pending",
              });
            }
          });

          setUploadedFiles((prev) => ({
            ...prev,
            [activeWorkspace.id]: processedFiles,
          }));

          setProcessingFiles((prev) => ({
            ...prev,
            [activeWorkspace.id]: stillProcessingFiles,
          }));
        } catch (error) {
          console.error("Status check error:", error);
        }
      };

      checkStatus();
      statusCheckInterval = setInterval(checkStatus, 10000);
    }
    return () => {
      if (statusCheckInterval) {
        clearInterval(statusCheckInterval);
      }
    };
  }, [activeWorkspace]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!input.trim()) return;

    const newMessage = { text: input, isUser: true };
    setMessages((prev) => [...prev, newMessage]);
    setInput("");
    setIsLoading(true);

    try {
      // Convert frontend messages format to API format
      const apiMessages = messages.concat(newMessage).map((msg) => ({
        role: msg.isUser ? "user" : "assistant",
        content: msg.text,
      }));
      const requestBody = {
        workspace_id: String(activeWorkspace.id),
        messages: apiMessages,
      };
      console.log("Request body:", requestBody);
      const response = await fetch(getApiEndpoint("chat"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          workspace_id: String(activeWorkspace.id),
          messages: apiMessages,
        }),
      });

      const data = await response.json();
      setMessages((prev) => [...prev, { text: data.message, isUser: false }]);
    } catch (error) {
      console.error("Error:", error);
      setMessages((prev) => [
        ...prev,
        {
          text: "Sorry, I encountered an error. Please try again.",
          isUser: false,
        },
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  const checkForDuplicateFileName = (filename) => {
    if (!activeWorkspace || !uploadedFiles[activeWorkspace.id]) return false;
    return uploadedFiles[activeWorkspace.id].some(
      (file) => file.name === filename
    );
  };

  const generateUniqueFileName = (originalName) => {
    if (!checkForDuplicateFileName(originalName)) return originalName;

    const nameParts = originalName.split(".");
    const extension = nameParts.pop();
    const baseName = nameParts.join(".");
    let counter = 1;
    let newName = `${baseName} (${counter}).${extension}`;

    while (checkForDuplicateFileName(newName)) {
      counter++;
      newName = `${baseName} (${counter}).${extension}`;
    }

    return newName;
  };
  const handleFileUpload = async (e) => {
    if (!activeWorkspace) {
      alert("Please select a workspace before uploading files.");
      return;
    }

    const files = Array.from(e.target.files);
    const formData = new FormData();
    const workspaceId = activeWorkspace.id;

    // Process each file and check for duplicates
    const processedFiles = files.map((file) => {
      const uniqueName = generateUniqueFileName(file.name);
      const renamedFile =
        uniqueName !== file.name
          ? new File([file], uniqueName, { type: file.type })
          : file;
      formData.append("files", renamedFile);
      return renamedFile;
    });

    // Update processing status for new files while preserving existing ones
    setProcessingFiles((prev) => {
      const existingFiles = prev[workspaceId] || [];
      const newFiles = processedFiles.map((file) => ({
        name: file.name,
        status: "pending",
      }));

      return {
        ...prev,
        [workspaceId]: [...existingFiles, ...newFiles],
      };
    });

    try {
      const response = await fetch(
        `${getApiEndpoint("uploadPdf")}?workspace_id=${workspaceId}`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log(data);

      const checkStatus = async () => {
        try {
          const statusResponse = await fetch(
            `${getApiEndpoint("checkStatus")}?workspace_id=${workspaceId}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          if (!statusResponse.ok) {
            throw new Error(`HTTP error! status: ${statusResponse.status}`);
          }

          const statusData = await statusResponse.json();

          setUploadedFiles((prev) => {
            const existingFiles = prev[workspaceId] || [];
            const newProcessedFiles = statusData.files
              .filter((file) => file.status === "processed")
              .map((file) => ({
                name: file.filename,
                createdAt: file.created_at,
                processedAt: file.processed_at,
              }));

            const mergedFiles = [...existingFiles];
            newProcessedFiles.forEach((newFile) => {
              if (
                !mergedFiles.some((existing) => existing.name === newFile.name)
              ) {
                mergedFiles.push(newFile);
              }
            });

            return {
              ...prev,
              [workspaceId]: mergedFiles,
            };
          });

          setProcessingFiles((prev) => {
            const currentProcessingFiles = prev[workspaceId] || [];
            const stillProcessingFiles = statusData.files
              .filter((file) => file.status === "pending")
              .map((file) => ({
                name: file.filename,
                status: "pending",
              }));

            // Remove files that are no longer processing
            const updatedProcessingFiles = currentProcessingFiles.filter(
              (file) =>
                stillProcessingFiles.some(
                  (processingFile) => processingFile.name === file.name
                )
            );

            return {
              ...prev,
              [workspaceId]: updatedProcessingFiles,
            };
          });
          // If any files are still processing, check again in 1 minute
          const currentProcessingFiles = statusData.files.filter(
            (file) => file.status === "pending"
          );

          // If any files are still processing, check again in 1 minute
          if (currentProcessingFiles.length > 0) {
            setTimeout(checkStatus, 60000);
          }
        } catch (error) {
          console.error("Status check error:", error);
        }
      };

      checkStatus();
    } catch (error) {
      console.error("Upload error:", error);
    }
  };

  const handleRemoveFile = async (fileIndex, isProcessing = false) => {
    if (!activeWorkspace) return;
    const workspaceId = activeWorkspace.id;
    const fileToRemove = isProcessing
      ? processingFiles[workspaceId][fileIndex]
      : uploadedFiles[workspaceId][fileIndex];
    if (!fileToRemove) return;
    setDeletingFiles((prev) => ({
      ...prev,
      [workspaceId]: new Set([...(prev[workspaceId] || []), fileToRemove.name]),
    }));
    try {
      const encodedFilename = encodeURIComponent(fileToRemove.name);
      console.log("Deleting file:", {
        workspaceId,
        filename: fileToRemove.name,
        encodedFilename,
        url: `${getApiEndpoint(
          "deletePdf"
        )}?workspace_id=${workspaceId}&filename=${encodedFilename}`,
      });

      const response = await fetch(
        `${getApiEndpoint(
          "deletePdf"
        )}?workspace_id=${workspaceId}&filename=${encodedFilename}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(
          `Failed to delete file: ${response.status} ${JSON.stringify(
            errorData
          )}`
        );
      }
      if (isProcessing) {
        setProcessingFiles((prev) => ({
          ...prev,
          [workspaceId]: prev[workspaceId].filter(
            (_, index) => index !== fileIndex
          ),
        }));
      } else {
        setUploadedFiles((prev) => ({
          ...prev,
          [workspaceId]: prev[workspaceId].filter(
            (_, index) => index !== fileIndex
          ),
        }));
      }
      console.log("File deleted successfully");
    } catch (error) {
      console.error("Error deleting file:", error);
      alert("Failed to delete file. Please try again.");
    } finally {
      setDeletingFiles((prev) => {
        const workspaceFiles = new Set(prev[workspaceId] || []);
        workspaceFiles.delete(fileToRemove.name);
        return {
          ...prev,
          [workspaceId]: workspaceFiles,
        };
      });
    }
  };
  const getCurrentWorkspaceFiles = () => {
    if (!activeWorkspace || !uploadedFiles[activeWorkspace.id]) return [];
    return uploadedFiles[activeWorkspace.id];
  };

  const getCurrentProcessingFiles = () => {
    if (!activeWorkspace || !processingFiles[activeWorkspace.id]) return [];
    return processingFiles[activeWorkspace.id];
  };

  return (
    <div className="chatbot-sidebar-container">
      <Sidebar
        activeWorkspace={activeWorkspace}
        setActiveWorkspace={setActiveWorkspace}
      />
      <div className="chatbot-container">
        <h2 className="upload-title">TALK TO ME</h2>
        <div className="messages-container" ref={containerRef}>
          {messages.map((message, index) => (
            <div
              key={index}
              className={`message ${
                message.isUser ? "user-message" : "bot-message"
              }`}
            >
              {message.text}
            </div>
          ))}
          {isLoading && (
            <div className="message bot-message loading">
              <div className="loading-dots">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </div>
            </div>
          )}
        </div>
        <form onSubmit={handleSubmit} className="input-form">
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Type your message..."
            className="message-input"
          />
          <button
            type="submit"
            disabled={isLoading || !input.trim()}
            className="send-button"
          >
            <SendIcon size={20} />
          </button>
        </form>

        <div className="upload-section">
          <button
            className="upload-files-btn"
            onClick={() => document.getElementById("file-upload").click()}
            disabled={!activeWorkspace}
          >
            <FileIcon size={16} />
            <span>Upload Files</span>
          </button>
          <input
            id="file-upload"
            type="file"
            multiple
            accept=".pdf"
            className="file-upload"
            onChange={handleFileUpload}
          />

          {(getCurrentWorkspaceFiles().length > 0 ||
            getCurrentProcessingFiles().length > 0) && (
            <div className="uploaded-files-list">
              {/* Display uploaded files */}
              {getCurrentWorkspaceFiles().map((file, index) => (
                <div key={`uploaded-${index}`} className="uploaded-file-item">
                  <FileIcon size={16} />
                  <span className="file-name">{file.name}</span>
                  <button
                    className="remove-file-btn"
                    onClick={() => handleRemoveFile(index)}
                    disabled={deletingFiles[activeWorkspace.id]?.has(file.name)}
                  >
                    {deletingFiles[activeWorkspace.id]?.has(file.name) ? (
                      <RefreshIcon
                        size={16}
                        className="spinning text-red-500"
                      />
                    ) : (
                      <XIcon size={16} />
                    )}
                  </button>
                </div>
              ))}

              {/* Display processing files */}
              {getCurrentProcessingFiles().map((file, index) => (
                <div key={`processing-${index}`} className="uploaded-file-item">
                  <FileIcon size={16} />
                  <span className="file-name">{file.name}</span>
                  {deletingFiles[activeWorkspace.id]?.has(file.name) ? null : (
                    <RefreshIcon size={14} className="spinning" />
                  )}
                  <button
                    className="remove-file-btn"
                    onClick={() => handleRemoveFile(index, true)}
                    disabled={deletingFiles[activeWorkspace.id]?.has(file.name)}
                  >
                    {deletingFiles[activeWorkspace.id]?.has(file.name) ? (
                      <RefreshIcon
                        size={16}
                        className="spinning text-red-500"
                      />
                    ) : (
                      <XIcon size={16} />
                    )}
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Chatbot;
