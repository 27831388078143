const config = {
  apiEndpoints: {
    development: {
      uploadPdf: "http://localhost:8000/upload_pdf",
      chat: "http://localhost:8000/chat",
      checkStatus: "http://localhost:8000/check_status",
      deletePdf: "http://localhost:8000/remove_pdf",
    },
    production: {
      uploadPdf: "https://uploadpdf-370549895102.asia-southeast1.run.app",
      chat: "https://mywebsite-370549895102.asia-southeast1.run.app",
      checkStatus:
        "https://checkuploadstatus-370549895102.asia-southeast1.run.app",
      deletePdf: "https://removepdf-370549895102.us-central1.run.app",
    },
  },
};

export const getApiEndpoint = (endpoint) => {
  const environment = process.env.NODE_ENV || "development";
  return config.apiEndpoints[environment][endpoint];
};
