import React, { useState } from "react";
import { Loader2 } from "lucide-react";
import "./ImageUpload.css";

const ImageUpload = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [roastResult, setRoastResult] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const handleImageSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        setError("Image size must be less than 5MB");
        return;
      }
      setSelectedImage(file);
      setPreviewUrl(URL.createObjectURL(file));
      setRoastResult("");
      setError("");
    }
  };

  const uploadImage = async () => {
    if (!selectedImage) {
      setError("Please select an image first");
      return;
    }
    setIsLoading(true);
    setRoastResult("");
    setError("");

    try {
      const reader = new FileReader();
      reader.readAsDataURL(selectedImage);
      reader.onloadend = async () => {
        const base64Data = reader.result.split(",")[1];
        try {
          const response = await fetch(
            "https://asia-southeast1-nomadic-botany-388713.cloudfunctions.net/roast-me",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                image_data: base64Data,
              }),
            }
          );
          const data = await response.json();
          if (!response.ok) {
            throw new Error(data.error || "Failed to get roast");
          }
          setRoastResult(data.roast);
        } catch (err) {
          setError(err.message || "Failed to upload image");
        } finally {
          setIsLoading(false);
        }
      };
    } catch (err) {
      setError("Failed to process image");
      setIsLoading(false);
    }
  };

  return (
    <div className="content-wrapper">
      <div className="upload-container">
        <h2 className="upload-title">Get Roasted! 🔥</h2>
        <div className="upload-content">
          <label className="file-input-label">
            <input
              type="file"
              className="file-input"
              accept="image/*"
              onChange={handleImageSelect}
            />
            <span>Choose an image</span>
          </label>

          {previewUrl && (
            <div className="preview-container">
              <img src={previewUrl} alt="Preview" className="preview-image" />
            </div>
          )}

          {error && <div className="error-message">{error}</div>}

          <button
            onClick={uploadImage}
            disabled={!selectedImage || isLoading}
            className={`upload-button ${
              !selectedImage || isLoading ? "disabled" : ""
            }`}
          >
            {isLoading && <Loader2 className="spinner" />}
            {isLoading ? "Getting Roasted..." : "Roast Me!"}
          </button>

          {isLoading && (
            <div className="loading-container">
              <Loader2 className="spinner large" />
              <p>AI is thinking of something clever...</p>
            </div>
          )}
        </div>
      </div>
      <div className="result-wrapper">
        {roastResult ? (
          <div className="result-container">
            <p className="result-text">{roastResult}</p>
          </div>
        ) : (
          <div className="result-container empty">
            <p className="result-text">
              Upload an image to see the roast result here
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageUpload;
