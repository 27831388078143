import React, { useState } from "react";
import ImageUpload from "./ImageUpload";
import Chatbot from "./Chatbot";
import "./Projects.css";
function Projects() {
  const [activeTab, setActiveTab] = useState("imageUpload"); // Default active tab

  return (
    <div>
      <div className="project-header">
        <h2 className="glitch-text">My Projects</h2>
        <p className="rainbow-text">
          Here are some of the projects I've worked on.
        </p>
        <div className="tabs">
          <button
            className={
              activeTab === "imageUpload" ? "tab-button active" : "tab-button"
            }
            onClick={() => setActiveTab("imageUpload")}
          >
            Photo Roaster
          </button>
          <button
            className={
              activeTab === "chatbot" ? "tab-button active" : "tab-button"
            }
            onClick={() => {
              setActiveTab("chatbot");
              console.log("Active Tab:", activeTab);
            }}
          >
            Chatbot
          </button>
        </div>
      </div>
      {/* Tab Navigation */}

      {/* Project Content */}
      <div className="projects-container">
        {activeTab === "imageUpload" && (
          <div className="project-card">
            <h3>Photo Roaster</h3>
            <p>Upload an image of yourself and get roasted with ease.</p>
            <ImageUpload />
          </div>
        )}

        {activeTab === "chatbot" && (
          <div className="project-card">
            <h3>RAG Chatbot</h3>
            <p>
              Interact with an AI-powered chatbot with knowledge of uploaded
              files.
            </p>
            <Chatbot />
          </div>
        )}
      </div>
    </div>
  );
}

export default Projects;
